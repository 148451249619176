@import '../../assets/style/variables.module.scss';

.header {
  position: fixed;
  top: 30px;
  right: 30px;
  z-index: 999;
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(17.450000762939453px);
  box-shadow: 0px 4px 46.2px 0px rgba(149, 149, 149, 0.24);

  padding: 7px 50px;
  padding-left: 20px;
  box-sizing: border-box;
  display: flex;
  gap: 30px;
  align-items: center;
  transition: all 0.5s ease;
  width: 375px;
  height: auto;

  .logo {
    img {
      height: 35px;
      width: 35px;
      transition: all 0.5s ease;
    }
  }

  ul {
    display: flex;
    gap: 20px;
    transition: all 0.5s ease;

    .products {
      a {
        &::before {
          content: 'soon';
          opacity: 0;
          width: 110%;
          height: 110%;
          background: black;
          color: white;
          position: absolute;
          top: 0;
          left: -1px;
          z-index: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          transform: rotate(0deg);
          transition: all 0.5s ease;
        }
      }
    }

    li {
      a {
        color: #101010;
        font-size: 14px;
        transition: all 0.5s ease;
        position: relative;
      }
    }
  }
}

.white {
  ul {
    li {
      a {
        color: white;
      }
    }
  }
}

@media only screen and (min-width: 940px) {
  .header {
    ul {
      .products {
        &:hover {
          a {
            &::before {
              opacity: 1;
              transform: rotate(10deg);
            }
          }
        }
      }
    }

    &:hover {
      width: 600px;
      ul {
        gap: 30px;
        li {
          a {
            font-size: 24px;
          }
        }
      }

      .logo {
        img {
          height: 70px;
          width: 70px;
        }
      }
    }
  }
}

@media only screen and (max-width: 940px) {
  .header {
    bottom: 0;
    top: unset;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
