.home {
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background: black;
  padding: 50px 0;
  padding-left: 50px;
  box-sizing: border-box;

  svg {
    height: 80vh;
  }

  .scene {
    width: 70vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    mix-blend-mode: darken;
    cursor: pointer;
  }
}

@media only screen and (max-width: 940px) {
  .home {
    padding-left: 0;
    padding: 20px;
    height: 100%;
    svg {
      min-height: -webkit-fill-available;
      max-height: -webkit-fill-available;
      min-width: -webkit-fill-available;
      width: 100%;
      max-width: -webkit-fill-available;
      height: auto;
    }

    .scene {
      width: 100vw;
      height: 50vh;
      transform: translateY(100px);
    }
  }
}
