@import '../../assets/style/variables.module.scss';

.workDetail {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .head {
    width: 100vw;
    height: 100vh;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    justify-content: center;
    position: relative;
    padding-top: 50px;
    flex-direction: column;
    align-items: center;
    padding-bottom: 70px;

    .info {
      transform: translateY(30px);
      border-top: 1px solid rgba($color: white, $alpha: 0.4);
      width: 90%;
      position: relative;
      z-index: 10;
      padding-top: 20px;
      display: flex;
      gap: 30px;

      .live {
        position: absolute;
        right: 0;

        a {
          display: flex;
          align-items: center;
          gap: 20px;
          color: white;
          font-family: $--CamptonMedium;
          text-align: center;
          svg {
            margin-top: -5px;
          }
        }
      }

      .column {
        color: white;
        h5 {
          font-size: 14px;
          font-family: $--CamptonBook;
        }
        ul {
          display: flex;
          flex-direction: column;
          margin-top: 10px;
          gap: 5px;
          li {
            font-size: 14px;
            font-family: $--CamptonSemibold;
          }
        }
      }
    }

    svg {
      position: relative;
      z-index: 0;
    }

    .scene {
      width: 100vw;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      mix-blend-mode: darken;
      cursor: pointer;

      .scrollShadow {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 100;
      }
    }
  }

  .workImages {
    display: flex;
    flex-direction: column;
    gap: 0;
    // padding: 0 300px;
    box-sizing: border-box;
    img {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 940px) {
  .workDetail {
    padding-left: 0;
    height: 100%;
    svg {
      width: 100%;
      height: 200px;
    }

    .head {
      height: auto;
      padding-top: 20px;
      justify-content: flex-start;

      .info {
        flex-direction: column;
        width: 100%;

        .live {
          position: static;

          svg {
            width: 100px;
            height: 20px;
          }
        }
      }
      .scene {
        width: 100vw;
        height: 30vh;
      }
    }
  }
}
