@import '../../assets/style/variables.module.scss';

.studio {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;

  .wrapper {
    width: 100%;
    max-width: 1200px;
    .head {
      .row {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        img {
          width: 40%;
        }
      }
      h1 {
        font-size: 12.3vh;
        font-family: $--CamptonLight;
        font-weight: 300;

        span {
          font-family: $--CamptonMediumItalic;
        }
      }
    }

    .info {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 100px;
      margin-top: 100px;
      .row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          font-size: 22px;
          font-family: $--CamptonLight;
          font-weight: 300;
          line-height: 38px;
          letter-spacing: 0.005em;
          width: 50%;
        }

        h3 {
          font-size: 64px;
          font-family: $--CamptonLight;
          font-weight: 300;
          position: relative;

          span {
            position: absolute;
            top: -10px;
            right: -50px;
            font-size: 14px;
            background: black;
            padding: 7px 20px;
            color: white;
            letter-spacing: 0.05em;
            transform: rotate(-10deg);
            border-radius: 10px;
          }
        }
      }
    }

    @keyframes fadeInDown {
      0% {
        opacity: 0;
        transform: translateY(-1.25em);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .services {
      margin-top: 100px;
      h5 {
        font-size: 36px;
        font-family: $--CamptonMediumItalic;
        margin-bottom: 60px;
      }

      .servicesWrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 50px;
        cursor: pointer;

        .serviceRow {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #b0b0b0;
          padding-bottom: 30px;

          .head {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            svg {
              transition: all 0.5s ease;
            }
          }

          .isOpen {
            svg {
              transform: rotate(45deg);
            }
          }

          .content {
            width: 100%;
            margin-top: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            animation-name: fadeInDown;
            animation-duration: 0.5s;

            ul {
              display: flex;
              flex-direction: column;
              gap: 35px;

              li {
                color: #565656;
                font-size: 34px;
                font-family: $--CamptonLight;
                font-weight: 300;
              }
            }

            p {
              font-size: 16px;
              width: 45%;
              line-height: 25px;
              letter-spacing: 0.02em;
              font-family: $--CamptonLight;
              margin-right: 40px;
            }
          }

          h6 {
            font-size: 7vh;
            font-family: $--CamptonLight;
            font-weight: 300;
            color: black;
          }
        }
      }
    }

    .technologies {
      margin-top: 100px;

      h5 {
        font-size: 36px;
        font-family: $--CamptonMediumItalic;
        margin-bottom: 100px;
      }

      .technologiesWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        gap: 120px;

        .image {
          width: 15%;
          position: relative;
          img {
            width: 100%;
          }

          .cover {
            width: 100%;
            height: 100%;
            background: white;
            position: absolute;
            top: 0;
            left: 0;
            mix-blend-mode: color;
            transition: all 0.5s ease;
          }

          &:hover {
            .cover {
              background: transparent;
              mix-blend-mode: color;
              opacity: 0;
            }
          }
        }
      }
    }

    .bottomInfo {
      margin-bottom: 100px;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .studio {
    position: relative;
    .wrapper {
      width: 90%;
      .head {
        .row {
          flex-direction: column;
          h1 {
            font-size: 42px;
            text-align: center;
          }

          img {
            width: 70%;
          }
        }
      }

      .info {
        gap: 50px;
        .row {
          width: 100%;
          flex-direction: column;
          align-items: flex-start;
          p {
            width: 100%;
            font-size: 16px;
            line-height: 30px;
          }

          h3 {
            margin-bottom: 20px;
            font-size: 42px;
          }
        }
      }

      .services {
        h5 {
          font-size: 22px;
        }

        .servicesWrapper {
          .serviceRow {
            h6 {
              font-size: 32px;
            }
            .content {
              ul {
                li {
                  font-size: 22px;
                }
              }
            }
          }
        }
      }

      .technologies {
        h5 {
          font-size: 22px;
          margin-bottom: 50px;
        }

        .technologiesWrapper {
          gap: 30px;
          .image {
            width: 25%;
          }
        }
      }

      .bottomInfo {
        margin: 50px 0;
      }
    }
  }
}
