@import '../../assets/style/variables.module.scss';

.works {
  width: 100%;
  box-sizing: border-box;
  padding: 50px 50px;
  background: black;
  transition: all 0.4s ease;

  .workList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 70px;

    a {
      width: 100%;
      height: 300px;
      transition: all 0.4 ease;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;

      .card {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        overflow: hidden;
        border-radius: 10px;
        border: 1px solid white;
        .imageList {
          width: 100%;
          height: 100%;
          display: flex;
          gap: 10px;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }

    .active {
      opacity: 1;
      svg {
        opacity: 0;
      }
    }

    .passive {
      opacity: 0.7;
    }
  }
}

@media only screen and (max-width: 940px) {
  .works {
    padding: 20px 20px;
    padding-bottom: 350px;

    h1 {
      font-size: 100px;
      color: white;
      font-family: $--CamptonLight;
      margin-top: 50px;
      margin-bottom: 130px;
      font-weight: 300;
    }

    .workList {
      grid-template-columns: 1fr;
      gap: 50px;

      a {
        height: 200px;
      }
    }
  }
}
