@import '../../assets/style/variables.module.scss';

.rotate {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.6);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    display: flex;
    gap: 10px;
    align-items: center;

    span {
      font-family: $--CamptonMedium;
      color: white;
      font-size: 14px;
    }
  }
}
