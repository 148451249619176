@font-face {
  font-family: 'Campton-Bold';
  src: url('../fonts/campton/Campton-Bold.woff2') format('woff2'),
    url('../fonts/campton/Campton-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Campton-Book';
  src: url('../fonts/campton/Campton-Book.woff2') format('woff2'),
    url('../fonts/campton/Campton-Book.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Campton-Light';
  src: url('../fonts/campton/Campton-Light.woff2') format('woff2'),
    url('../fonts/campton/Campton-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Campton-Medium';
  src: url('../fonts/campton/Campton-Medium.woff2') format('woff2'),
    url('../fonts/campton/Campton-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Campton-MediumItalic';
  src: url('../fonts/campton/Campton-MediumItalic.woff2') format('woff2'),
    url('../fonts/campton/Campton-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Campton-Semibold';
  src: url('../fonts/campton/Campton-SemiBold.woff2') format('woff2'),
    url('../fonts/campton/Campton-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Campton-Thin';
  src: url('../fonts/campton/Campton-Thin.woff2') format('woff2'),
    url('../fonts/campton/Campton-Thin.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

$--CamptonLight: 'Campton-Light', sans-serif;
$--CamptonSemibold: 'Campton-Semibold', sans-serif;
$--CamptonBold: 'Campton-Bold', sans-serif;
$--CamptonBook: 'Campton-Book', sans-serif;
$--CamptonMedium: 'Campton-Medium', sans-serif;
$--CamptonMediumItalic: 'Campton-MediumItalic', sans-serif;
$--CamptonThin: 'Campton-Thin', sans-serif;

html {
  scroll-behavior: smooth;
}

body {
  font-family: $--CamptonBook;
}
