.contact {
  width: 100vw;
  min-height: 100vh;
  background: black;
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  flex-direction: column;

  .svgWrapper {
    width: 100%;
    align-items: flex-start;
    svg {
      width: 70vw;
      height: 70vh;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    gap: 20px;

    .mail {
      color: white;
      font-size: 64px;
      text-decoration: underline;
      font-weight: 300;
    }

    .social {
      display: flex;
      gap: 15px;
    }
  }
}

@media only screen and (max-width: 940px) {
  .contact {
    .svgWrapper {
      width: 100%;
      align-items: flex-start;
      svg {
        width: 100%;
        height: auto;
      }
    }
    .info {
      .mail {
        font-size: 24px;
      }
    }
  }
}
